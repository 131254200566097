import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abh-footer',
  templateUrl: './abh-footer.component.html',
  styleUrls: ['./abh-footer.component.scss']
})
export class AbhFooterComponent implements OnInit {
  hostname: any;

  constructor() {
    this.changeIcon();
  }

  ngOnInit(): void {
  }
  async findHostName() {
    return window.location.hostname;
  }

  async changeIcon() {
    const hostname = await this.findHostName();
    const splithostname = hostname.split(".");
    this.hostname = splithostname[0];
  }

}
