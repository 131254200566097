import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-desk-sidebar',
  templateUrl: './desk-sidebar.component.html',
  styleUrls: ['./desk-sidebar.component.scss']
})
export class DeskSidebarComponent implements OnInit {

  userDetails: any;
  casino_type: string;
  casino_menu: boolean = true;
  live_casino_menu: boolean = true;

  constructor(public router: Router, public toastr: ToastrService, public httpClient: UsersService) {
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.httpClient.getSportEvents().subscribe((data) => {
      //message contains the data sent from service
      this.getUserEvenets();
      // this.betSub.unsubscribe();
    });
    this.getUserEvenets();

  }

  ngOnInit(): void {
  }

  getUserEvenets() {
    const data = {
      details: {
        username: this.userDetails.details.username,
        _id: this.userDetails._id,
        key: this.userDetails.key,
        role: this.userDetails.details.role,
        token: this.userDetails.verifytoken,
      },
      targetUser: {
        username: this.userDetails.details.username,
        role: this.userDetails.details.role,
        status: this.userDetails.details.status,
      },
    };

    this.httpClient.Post("getUserEvenets", data).subscribe((res: any) => {

      if (res.success) {
        this.casino_menu = res.response.availableEventTypes.includes("c9");
        this.live_casino_menu = res.response.availableEventTypes.includes("c1");
      }
      else {
        console.warn(res.message);
      }
    });

  }

  openCasino(gameID: string, tableID: string, type: string) {
    if (this.userDetails.details.betStatus) {
      const data = { gameId: gameID, tableId: tableID };
      sessionStorage.setItem('casinoDb', JSON.stringify(data));
      this.router.navigate(['./casino/' + type]);

    }
    else {
      this.toastr.error('Error in placing bet.Bet Disable pls Contact Upline.');
    }

  }

}
