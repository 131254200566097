<section class="footer footer-login">
  <div class="footer-top">
    <div class="support-detail">
      <h2>24X7 Support</h2>
    </div>
    <div class="social-icons-box">
      <div class="social-icon">
        <a target="_blank">
          <img src="../../assets/images/signal.png" alt="Signal">
        </a>
      </div>
    </div>
  </div>
</section>


<div class="footer-bottom">
  <div class="secure-logo">
    <div>
      <img src="../../assets/images/ssl.png">
    </div>
    <div class="ml-2">
      <b style="color: black;">100% SAFE</b>
      <div>Protected connection and encrypted data.</div>
    </div>
  </div>
  <div class="d-inline-block">
    <a href="javascript:void(0)">
      <img src="../../assets/images/18plus.png">
    </a>
    <a href="javascript:void(0)">
      <img src="../../assets/images/gamecare.png">
    </a>
    <a href="javascript:void(0)">
      <img src="../../assets/images/gt.png">
    </a>
  </div>
</div>

<div class="footer-text">
  <p class="text-center">© Copyright 2023. All Rights Reserved. Powered by {{hostname}}.</p>
</div>