<div data-toggle="collapse" data-target=".casino" class="sidebar-title" aria-expanded="true">
  <h5 class="d-inline-block m-b-0">Others</h5>
</div>
<nav class="casino sidebarmenus collapse show">
  <ul>
    <ng-container *ngIf="casino_menu">
      <li class="nav-item" [routerLink]="['/livecasino']"><a href="javascript:void(0)" class="nav-link"><span
            class="new-launch-text" style="text-transform: uppercase;">Live Casino</span></a></li>
      <li class="nav-item" [routerLink]="['/slotgame']"><a href="javascript:void(0)" class="nav-link"><span
            class="new-launch-text" style="text-transform: uppercase;">Slot Game</span></a></li>
    </ng-container>
    <ng-container *ngIf="live_casino_menu">
      <li class="nav-item" (click)="openCasino('','90100','race_20_20');"><a href="javascript:void(0)"
          class="nav-link"><span style="text-transform: uppercase;">Race 20-20</span></a></li>
      <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span style="text-transform: uppercase;">Casino
            Queen</span></a></li>
      <li class="nav-item" (click)="openCasino('','98790','dragon_tiger');"><a href="javascript:void(0)"
          class="nav-link"><span style="text-transform: uppercase;">Dragon Tiger</span></a></li>
      <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span style="text-transform: uppercase;">Sports
            Casino</span></a></li>
      <li class="nav-item" (click)="openCasino('','87564','andar_bahar')"><a href="javascript:void(0)"
          class="nav-link"><span style="text-transform: uppercase;">Andar Bahar</span></a></li>
      <li class="nav-item" (click)="openCasino('','67570','bollywood_casino')"><a href="javascript:void(0)"
          class="nav-link"><span style="text-transform: uppercase;">Bollywood Casino</span></a></li>
      <li class="nav-item" (click)="openCasino('','67580','casino_war')"><a href="javascript:void(0)"
          class="nav-link"><span style="text-transform: uppercase;">Casino War</span></a></li>
      <li class="nav-item" (click)="openCasino('','92037','worli')"><a href="javascript:void(0)" class="nav-link"><span
            style="text-transform: uppercase;">Worli</span></a></li>
    </ng-container>

    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span
          style="text-transform: uppercase;">Lottery</span></a></li>
    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span style="text-transform: uppercase;">3
          Cards Judgement</span></a></li>
    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span
          style="text-transform: uppercase;">Binary</span></a></li>

    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span style="text-transform: uppercase;">Virtual
          Sports</span></a></li>
    <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><span style="text-transform: uppercase;">Cricket
          Casino</span></a></li>
  </ul>
</nav>


<div data-toggle="collapse" data-target=".eventsidebar" class="sidebar-title" aria-expanded="true">
  <h5 class="d-inline-block m-b-0">All Sports</h5>
</div>


<div id="events" class="mtree-main  eventsidebar collapse show">
  <div class="ps">
    <nav>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Football
          </div>
          <!-- <ul class="mtree-level-1" style="display: none;">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AFRICA Africa Cup of Nations - Qualification
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ARGENTINA Copa Argentina
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ARGENTINA Primera Nacional
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ARGENTINA Youth League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA Northern NSW - Premier League Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA Capital Territory NPL2
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA New South Wales Cup
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA New South Wales NPL Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA NPL Queensland Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA NPL Queensland U23
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA NPL Victoria
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRALIA WA Premier League Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> AUSTRIA Salzburg
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> BRAZIL Gaucho Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> BRAZIL Serie B
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHINA Jia League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHINA Yi League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> COLOMBIA Primera A
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> COSTA RICA Primera Division
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CROATIA Prva NL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CZECH REPUBLIC Division F
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CZECH REPUBLIC MSFL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ECUADOR Liga Pro
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ENGLAND Womens Championship
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> EUROPE Euro - Qualification
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> GERMANY Oberliga
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> INDONESIA Liga 2
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ITALY Serie C
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN Football League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN J2 League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN J3 League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN Regional League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN WE League Cup Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> JAPAN YBC Levain Cup - Play Offs
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> MEXICO Liga de Expansion MX
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> NORTH &amp; CENTRAL AMERICA CONCACAF Nations League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> PERU LIGA 1
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> PERU Reserve League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> POLAND III Liga
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ROMANIA Liga 1
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SERBIA Prva Liga
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SOUTH AFRICA Diski Challenge
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SOUTH KOREA K3 League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SOUTH KOREA K4 League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SPAIN LaLiga2
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SPAIN Primera Federacion Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SPAIN Primera RFEF
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SPAIN Segunda RFEF
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SPAIN Tercera RFEF
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SWEDEN Division 2
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> THAILAND League Cup
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> USA MLS
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> USA USL Championship
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WORLD Friendly International
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Tennis
          </div>
          <!-- <ul class="mtree-level-1" style="display: none;">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ATP - SINGLES US OPEN (USA)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> ATP - SINGLES US OPEN (USA) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES GENOVA (ITALY) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES CASSIS (FRANCE) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES Guangzhou (China)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES ISTANBUL (TURKEY) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES Rennes (France)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES SHANGHAI (CHINA)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES SHANGHAI (CHINA) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES Szczecin (Poland)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER MEN - SINGLES TULLN (AUSTRIA) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER WOMEN - SINGLES BARI (ITALY) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CHALLENGER WOMEN - SINGLES Bucharest (Romania)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Hopman Cup 2023
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> US Open Men 2023
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UTR PRO TENNIS SERIES LOS ANGELES WOMEN
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UTR PRO TENNIS SERIES SOFIA
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UTR PRO TENNIS SERIES SOFIA WOMEN
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UTR PRO TENNIS SERIES WINSTON-SALEM
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WTA - DOUBLES US OPEN (USA) - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WTA - SINGLES Osaka (Japan)
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Zhangjiagang Challenger 2023
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Cricket
          </div>
          <!-- <ul class="mtree-level-1" style="display: none;">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Asia Cup
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Australia Under-19s tour of England
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Caribbean Premier League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> County Championship Division 1
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> One Day Internationals
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Rachael Heyhoe Flint Trophy
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Sat7e Pe Sat7a Indoor Cricket T10&nbsp;League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> T10 XI
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> T5 XI
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Virtual Cricket League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> Women's Caribbean Premier League
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Ice Hockey
          </div>
          <!-- <ul class="mtree-level-1" style="display: none;">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> DENMARK Metal Ligaen
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> EUROPE Champions League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> KAZAKHSTAN Championship
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> POLAND Polish Hockey League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SLOVAKIA Slovakia Cup
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Volleyball
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Politics
          </div>
          <!-- <ul class="mtree-level-1">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-minus-square"></i>
                   </span> Assembly Election 2023
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Basketball
          </div>
          <!-- <ul class="mtree-level-1">
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-minus-square"></i>
                   </span> BRAZIL Campeonato Paulista U20 W
                 </div>
                 <ul class="mtree-level-1" >
                   <li class="mtree-node text-dark">
                     <div class="text-dark">
                       <span>
                         <i class="far fa-minus-square"></i>
                       </span> 2023/09/10
                     </div>
                     <ul class="mtree-level-1">
                       <li class="mtree-node text-dark">
                         <div class="text-dark">
                           <span>
                             <i class="fas fa-caret-right"></i>
                           </span> Ar Praia Grande W U20 - Sesi/araraquara W U20
                         </div>
                         <ul class="mtree-level-1" style=""></ul>
                       </li>
                     </ul>
                   </li>
                 </ul>
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> BRAZIL Paulista 1 Division
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CUBA TNA
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> CUBA TNA Cup Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> FIBA World Cup 2023
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> NEW ZEALAND Tauihi Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> SENEGAL Division 1
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UGANDA National Basketball League
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> UGANDA NBL Women
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WORLD WORLD CUP - FINAL
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WORLD Club Friendly
                 </div>
                
               </li>
               <li class="mtree-node text-dark">
                 <div class="text-dark">
                   <span>
                     <i class="far fa-plus-square"></i>
                   </span> WORLD WORLD CUP - 3RD PLACE
                 </div>
                
               </li>
             </ul> -->
        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Table Tennis
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Darts
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Badminton
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Kabaddi
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Boxing
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Mixed Martial Arts
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Motor Sport
          </div>

        </li>
      </ul>
      <ul class="mtree transit bubba">
        <li class="mtree-node item">
          <div class="text-dark">
            <span>
              <i class="far fa-plus-square"></i>
            </span> Boat Racing
          </div>

        </li>
      </ul>

    </nav>
  </div>
</div>